.table-row {
  &:hover {
    background-color: rgba(0, 0, 0, 0.075) !important;
  }
}

.status-tooltip {
  border-left: 5px solid silver;
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  border-right: 1px solid silver;
  border-radius: 5px;
  min-height: 30px;
  display: flex;
  align-items: center;
  background: #fff !important;
  color: #000 !important;
  opacity: 1 !important;
}

.tooltip-ok {
  border-color: var(--success) !important;
}

.tooltip-warning {
  border-color: var(--warning) !important;
}

.tooltip-critical-warning {
  border-color: var(--criticalwarning) !important;
}

.tooltip-no-status {
  border-color: #6d6e71;
}

.td_button {
  width: 75px;
}

.custom-rivata-dropdown {
  min-width: 120px;
  border: 1px solid silver;
  text-align: left;

  &::after {
    position: absolute;
    right: 5%;
    top: 45%;
  }
}

th {
  .form-check-label {
    margin-left: 1.25rem;

    .form-check-input {
      margin-top: 0.17rem;
      margin-left: -1.25rem;
    }
  }
}

.table__sort {
  cursor: pointer;
}

.table-row {
  height: 34px !important;

  textarea {
    width: 100%;
    height: 200px;
    margin-top: 5px;
  }

  .nav-link {
    padding: 0;
  }

  .form-check-label {
    display: inline-flex;
    align-items: center;

    img {
      width: 30px;
    }

    .form-check-input {
      margin-top: 0;
      margin-left: 0;
    }
  }
}

.absolute-loader {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}

@media (min-width: 768px) {
  .card {
    margin-bottom: 10px;
  }
}

.btn-white {
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.dropdown-item .form-check-input {
  position: static;
  margin: 0 0.5rem 0 0;
  cursor: pointer;
}

.dropdown-divider {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown-item.set-default {
  text-align: end;
}

.fake_link_div {
  width: 100%;
  height: 30px;
  background: transparent;
}

.icon-container {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 5px 8px 5px 0;
}

.count-badge {
  pointer-events: none;
  z-index: 1;
  position: absolute;
  border-radius: 50%;
  background-color: var(--primary);
  font-size: 12px;
  color: #fff;
  padding: 2px 8px;
  margin-top: -5px;
  left: 20px;
}

.rivata-table-icon-button:focus {
  border: none;
  outline: none;
}

.rivata-table-icon-button {
  float: none;

}

.rivata-table-icon-button-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

td .json-editor {
  height: 200px;
}