.bulk-upload-uploaded-assets {
  .uploaded-assets-rows-wrapper {
    max-height: 150px;
    overflow-y: auto;
  }
  .uploaded-assets-row {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e9ecef;
    padding: 0 5px;
    .name {
      width: 45%;
      margin: 0;
      display: flex;
      align-items: center;
    }
    .vin {
      width: 45%;
      margin: 0;
      display: flex;
      align-items: center;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.075) !important;
    }
  }
}
.bulk-assets-with-hardware {
  .assets-with-hardware-rows-wrapper {
    max-height: 150px;
    overflow-y: auto;
  }
  .assets-with-hardware-row {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e9ecef;
    padding: 0 5px;
    .name,
    .vin,
    .type,
    .mac {
      width: 23%;
      margin: 0;
      display: flex;
      align-items: center;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.075) !important;
    }
  }
}
