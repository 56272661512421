.report-users-search {
    .report-user-search__wrapper {
        display: flex;
        position: relative;
        width: 100%;

        .rivata-menu__list {
            top: 34px;
        }
    }
}

.report-users {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    .manage-users__btn {
        max-height: 35px;
    }
}