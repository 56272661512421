.left-sidebar{
    z-index: 1000;
}

.versions_wrapper{
    position: absolute;
    bottom: 0; 
    left: 20px;
    padding-top: 30px;
    white-space: nowrap;
}

.sidebar-item {
    width: 100%;
    height: 45px !important;

    a {
        display: flex;
        align-items: center;
        // margin-left: 0.5rem;
        padding: 100px;
        overflow: hidden;


        .nav-icon__wrapper {
            width: 35px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img {
            width: 18px;
            height: 21px;
        }
    }

    &.active {
        a {
            color: #000!important;
            opacity: 1!important;
        }
    }
    
}


 @media (max-height: 768px) {
    .sidebar-item {
    height: 40px !important
}
 }
 
 @media (max-height: 720px) {
    .versions_wrapper {
    bottom: -200px;
}
 }

 @media (max-height: 600px) {
    .versions_wrapper {
    bottom: -400px;
}
 }


