.diagram-container {
  width: 100%;
  margin: 50px 0 50px 0;
}

.tractor-rear {
  background: #F3F3F3;
  border-left: 2.5px solid rgba(0,0,0, 0.06);
  border-top: 2.5px solid rgba(0,0,0, 0.06);
  border-bottom: 2.5px solid rgba(0,0,0, 0.06);
}
