.tpms-table {
    tbody, thead {
        border-top: 2px solid DarkGray !important;
    }

    tbody:hover {
        background: rgba(0, 0, 0, 0.075);
    }

    tr {
        border-top: 1px solid silver;
    }

    thead th {
        border: unset;
        font-size: 14px;
        padding: 5px;
        vertical-align: middle !important;

        .th-items__wrapper {
            user-select: none;
            
            i {
                margin-left: 0.5rem;
            }
        }
    }

    .primary-column {
        max-width: 145px;
        text-align: left;
    }

    .sensor-column {
        text-align: center;
    }

    .primary-column + .sensor-column, .sensor-column:first-child {
        border-left: 2px solid DarkGray;
    }

    th.primary-column {
        border-right: 1px solid silver;
        cursor: pointer;
    }

    th.sensor-column {
        border-left: 1px solid silver;
        font-size: 12px;
    }

    td {
        padding: 5px;
        min-width: 30px;
        border: unset;

        a {
            display: block;
            width: 100%;
        }
    }

    td.sensor-column {
        padding: 0;
        width: unset !important;
        font-size: 14px;
    }

    .warning {
        background: var(--warning);
        border-right: 1px solid silver;
    }

    .criticalWarning{
        background: var(--criticalwarning);
        color: #fff;
        border-right: 1px solid silver;
    }

    .noStatus {
        background: #e5e5e5;
        color: #fff;
        border-right: 1px solid silver;
    }
}

.tire-tooltip-margin {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem;
}