.playback-controls {
  margin: 40px 0 20px 0;

  .container {
    padding: 0 0 0 10px;
    .control {
      width: 60px;
      margin-right: 10px;
    }
  }
}
