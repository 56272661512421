.asset-table-controls {
  align-items: center;
  margin-left: 0.25rem;
  margin-bottom: 1rem;

  .form-check-input {
      margin-top: .17rem;
  }
}

.dates-dropdown__desc {
  display: flex;
  align-items: center;

  & p {
    margin-bottom: 0;
    margin-right: 5px;
  }
}