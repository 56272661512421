@import "../../assets/scss/mixins.scss";

.report {
    .report-date-picker {
        input {
          min-width: 200px;
        }
    }
      
    .report-date_input {
        border: 1px solid silver;
        position: absolute;
        left: 0;
        top: 60px !important;
        z-index: 200;

    }

    .dropdown-checklist-menu {
        
        .menu-input {
            margin-left: 5px;
            margin-right: 5px;
            border: 1px solid silver;
            border-radius: 3px;
            width: -webkit-fill-available;
            padding: 2px;
            font-size: 14px;
            margin-bottom: 0.5rem;
        }
        
        .menu-body {
            li {
                list-style: none;
                font-weight: bold;
                padding: 5px 10px;
            
                & * {
                cursor: pointer;
                }
            }
        
            .status-message {
                font-size: 1rem;
                font-weight: 600;
            }
        }
        
        .menu-list {
            padding: 0;
            overflow-y: auto;
            max-height: 235px;
            
            @include custom-scrollbar(#F5F5F5, silver);
        }  
    }

    .warning-type-select, .warning-status-select {
        margin-bottom: 12px;
    }
}