.loader__wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .brand-logo {
    display: flex;
    justify-content: center;
    margin: 300px auto 0 auto;
    position: relative;
  
    img {
      margin-left: 15px;
      max-width: 150px;
      width: 100%;
      animation: pulse 3s infinite;
      animation-fill-mode: forwards;
    }
  }
  
  .animated-loader {
    position: absolute;
    height: 100%;
    width: 200px;
  }
  
  .loading__item {
    position: absolute;
    top: -36px;
    left: 50%;
    display: block;
    height: 10px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #32afc9;
    animation: loading 1.2s infinite;
    animation-timing-function: linear;
  }
  
  @for $i from 1 through 12 {
    .loading__item:nth-child(#{$i}n) {
      // animation-delay: #{$i * 0.167}s; if animation is 2s
      // animation-delay: #{$i * 0.083}s; if animation is 1s
      animation-delay: #{$i * 0.1}s; 
    }
  }
  
  @keyframes pulse {
    from {
      max-width: 150px;
      animation-timing-function: ease-out;
    }
    50% {
      max-width: 200px;
      animation-timing-function: ease-out;
    }
    to {
      max-width: 150px;
      animation-timing-function: ease-out;
    }
  }
  
  @keyframes loading {
    from {
      top: -36px;
      left: 50%;
    }
    8% {
      left: 74%;
      transform: rotate(30deg);
      top: -23px;
    }
    16% {
      top: 11px;
      left: 91%;
      transform: rotate(60deg);
    }
    25% {
      top: 56px;
      left: 98%;
      transform: rotate(90deg);
    }
    33% {
      top: 105px;
      left: 92%;
      transform: rotate(120deg);
    }
    41% {
      top: 141px;
      left: 74%;
      transform: rotate(150deg);
    }
    50% {
      top: 154px;
      left: 50%;
      transform: rotate(180deg);
    }
    58% {
      left: 26%;
      top: 140px;
      transform: rotate(210deg);
    }
    66% {
      top: 105px;
      left: 9%;
      transform: rotate(240deg);
    }
    75% {
      left: 3%;
      top: 56px;
      transform: rotate(270deg);
    }
    83% {
      left: 10%;
      top: 11px;
      transform: rotate(300deg);
    }
    91% {
      top: -23px;
      left: 26%;
      transform: rotate(330deg);
    }
    to {
      top: -36px;
      left: 50%;
      transform: rotate(360deg);
    }
  }