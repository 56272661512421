.picker {
  width: 95px;
  height: 34px;
  position: relative;
  .rc-time-picker-input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 95;
    font-size: 0.875rem;
    text-transform: uppercase;
    margin: 0;
    color: #525f7f;
    border-radius: 1px;
    border: 1px solid #e9ecef;
    height: 100%;
    &:disabled {
      cursor: text;
      color: #525f7f;
      background: #fff;
    }
  }
}
.logsPicker{
  @extend.picker;
  .rc-time-picker-input{
    z-index: 1060;
  }
}

.icon {
  position: absolute;
  z-index: 95;
  color: black;
  font-size: 16px;
  top: 50%;
  transform: translate(0, -50%);
  right: 10px;
  cursor: pointer;
}

.logsIcon {
  @extend.icon;
  z-index: 1060
}

.popup {
  width: 140px !important;
  z-index: 100 !important;
  &::before {
    content: "";
    display: flex;
    width: 137px;
    height: 40px;
    background-color: transparent;
  }
  .rc-time-picker-panel-inner {
    width: 100%;
    padding-bottom: 4px;
    border: 1px solid silver;
    box-shadow: none;
    border-radius: 1px;
    .rc-time-picker-panel-input-wrap {
      display: none;
    }
    .rc-time-picker-panel-combobox {
      margin: 4px;
      .rc-time-picker-panel-select {
        width: 40px;
        border: none;
        &:nth-child(2) {
          margin: 0 4px;
        }
        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        &:last-child {
          ul {
            li {
              text-transform: uppercase;
            }
          }
        }
        ul {
          .rc-time-picker-panel-select-option-selected {
            background-color: #2a9df4;
            color: white;
          }
          li {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            &:focus {
              border: 1px solid black;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
}

.logsPanel{
  @extend.popup;
  z-index: 1055 !important;
}
