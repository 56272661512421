.upload_csv {
    span {
        margin: 0 6px 0 6px
    }
    input {
        width: 200px;
    }
    margin-top: 10px;
}
.upload_csv_error {
    color: red;
    margin-left: 6px;
}

.assigned_cargo_tags {
    margin-left: 6px;
}

.download-sample {
    margin-left: 6px;
    margin-top: 20px;
}
