@import '../../assets/scss/mixins.scss';
@import '~react-data-grid/lib/styles.css';
.rivata-grid {
  @include custom-scrollbar(#f5f5f5, silver);

  .rdg-cell {
    user-select: text !important;
    box-shadow: none !important;
  }

  .react-grid-HeaderCell,
  .react-grid-Cell {
    box-sizing: border-box;
  }

  .rdg-selected {
    box-sizing: border-box;
  }

  .cell__expander {
    cursor: pointer;
    user-select: none;
  }

  .row__highlight {
    background: var(--primary);
  }
}
