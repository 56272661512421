.filter__btn {
    padding: 7px 20px 7px 20px;
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    &.btn__default {
        color: #444545;
        background: #FDFDFD;
        border: 1px solid #B6B8B8;
    }

    &.btn__primary {
        color: #3A6167;
        background: rgba(var(--primary-rgb), 0.13);
        border: 1px solid rgba(var(--primary-rgb), 0.6);
    }

    &.btn__primary-filled {
        font-size: 1rem;
        color: white;
        background: var(--primary);
        border: none;
    }

    &.btn__disabled {
        background: #dcddde;
        border: 1px solid #B6B8B8;
    }

    &:focus {
        outline: none;
    }
}