.tables-wrapper {
  width: 100%;

  td:nth-child(1) {
    width: 150px;
  }

  td {
    min-width: 110px;
  }
}

.asset-table-controls {
  align-items: center;
  margin-left: 0.25rem;
  margin-bottom: 1rem;

  .form-check-input {
    margin-top: .17rem;
  }
}

.dates-dropdown__desc {
  display: flex;
  align-items: center;

  & p {
    margin-bottom: 0;
    margin-right: 5px;
  }
}

.nav-tabs {
  .tab-item {
    color: #3e5569;
    cursor: pointer;
    background: #dcddde;
  }

  .nav-link:hover,
  .nav-pills .nav-link:hover {
    color: #3e5569;
  }
}

.tooltip-inner {
  max-width: 250px;
}

.status-circle {
  display: inline-block;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50% 50%;
  background-color: #6D6E71;
}

.status-circle--good {
  background-color: var(--success);
}

.status-circle--warning {
  background-color: var(--warning);
}

.status-circle--critical-warning {
  background-color: var(--criticalwarning);
}

.extra-height-content {
  margin-bottom: 320px;
}

.favorite-star-on {
  -webkit-text-stroke: 2px black;
  color: gold !important;
}

.favorite-star-off {
  -webkit-text-stroke: 2px black;
  color: lightgrey !important;
}