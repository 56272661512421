.rdrMonthAndYearPickers,
.rdrDefinedRangesWrapper {
  display: none;
}

.rdrStartEdge,
.rdrEndEdge {
  color: rgba(70, 132, 147, 1) !important;
  border-radius: 100%;
  left: 3px;
  right: 3px;
}

.rdrDayNumber {
  font-size: 14px;
  font-weight: 400;
}

.rdrDay {
  height: 4em;
}

.rdrInRange {
  background: rgba(228, 247, 252, 1);

  .rdrDayNumber {
    + p {
      color: black;
    }
  }
}

.rdrDay:not(.rdrDayPassive) {
  & .rdrInRange ~ .rdrDayNumber span {
    color: black;
  }
}

.rdrDay:not(.rdrDayPassive) {
  color: black !important;
}

.rdrMonthName {
  text-align: center;
  font-size: 16px;
  color: rgba(26, 26, 26, 1);
  font-weight: 400;
}

.rdrMonthAndYearWrapper {
  position: absolute;
  padding-top: 0;
  width: 664px;
  height: 48px;
}

.rdrDateRangePickerWrapper {
  margin-left: -10px;
  margin-top: 30px;
}

.inputs-container-wide {
  width: 410px;
}

.inputs-container {
  width: 380px;
}

.form-container {
  position: absolute;
  z-index: 1050;
  background-color: white;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  width: 725px;
  padding: 28px 40px 28px 40px;
  border-radius: 14px;
  border: 0.5px;
  gap: 32px;
  border: 0.5px solid rgba(233, 234, 236, 1);

  &--top-left-aligned {
    top: 15px;
    left: 15px;
  }

  &--top-right-aligned {
    top: 15px;
    right: 15px;
  }

  &--top-right-aligned-no-indent {
    top: 0;
    right: 15px;
  }

  .header-container {
    border-bottom: 1px solid rgba(233, 234, 236, 1);
    padding-bottom: 16px;
    align-items: center;
  }

  .header-right-container {
    display: flex;
    justify-content: right;

    &__content {
      align-items: center;
      justify-content: right;
    }
  }

  .reset-btn {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: center;
  }
}

.calendar-date-input,
.calendar-date-wide-input,
.calendar-input,
.calendar-wide-input {
  width: 140px;
  height: 48px;
  padding: 10px 12px;
  border-radius: 12px;
  border: 2px solid rgba(212, 214, 216, 1);

  &:focus {
    border: 2px solid rgba(0, 156, 218, 1);
  }
}

.calendar-date-input {
  width: 170px;
}

.calendar-wide-input {
  width: 200px;
}

.label {
  font-size: 14px;
}

.info-tooltip {
  z-index: 1050;
  background-color: #fff;
  position: absolute;
  top: -100px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 358px;
  height: 90px;
  padding: 16px 16px 12px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: left;
  border: 0.5px solid rgba(204, 206, 207, 1);
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06);
}

.info-notice {
  line-height: 21px;
}