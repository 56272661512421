@import "../../assets/scss/mixins.scss";

.label-selector {
    position: relative;

    .label-selector__displayer {
        position: relative;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid silver;
        padding: 12px;
        background-color: #FDFDFD;
        position: relative;
        display: flex;
        align-items: center;
        height: 48px;

        -webkit-text-security: none;
        color: darkGray;
        white-space: pre;
        word-wrap: normal;
        overflow: hidden;
        opacity: 0.8;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;

        .selected-label__count {
            display: flex;
            align-items: center;
            margin-left: 0.2rem;
            color: #3e5569;
            font-size: 16px;
        }
    }

    .label__btn {
        padding: 0.2rem;
        margin: 0.2rem;
        max-height: 28px;
        cursor: default;
        display: flex;
        justify-content: space-between;

        &.limited_size {
            max-width: 100px;
        }

        .btn__text {
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
        }

        span, i {
            padding: 3px;
            vertical-align: sub;

            &.label__delete {
                cursor: pointer;
                vertical-align: middle;
            }
        }
    }

    .label-selector__menu {
        position: absolute;
        z-index: 400;
        top: 0;
        background-color: #FDFDFD;
        border: 1px solid silver;
        border-radius: 5px;

        box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.05), 0px 16px 24px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.06);

        .menu__search {
            .custom__input {
                border: none;
            }

            .search-btn__finish {
                color: var(--primary);
                cursor: pointer;
                padding: 0.5rem;
            }
        }

        .selected__labels {
            padding: 0.5rem;
            max-height: 200px;
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            flex-wrap: wrap;

            @include custom-menu-scrollbar(#F5F7F7, #CCCECF, #CCCECF);
        }

        .clear__btn {
            cursor: pointer;
            padding: 0.5rem 0 1rem 0.5rem;
            font-weight: bold;
        }

        label {
            color: #B6B8B8;
            padding-left: 0.5rem;
        }

        .menu_list {
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                max-height: 200px;
                overflow-y: auto;
                overflow-x: hidden;

                @include custom-menu-scrollbar(#F5F7F7, #CCCECF, #CCCECF);
    
                li {
                    padding: 0.5rem 1rem;
                    border-bottom: 1px solid #F5F7F7;
                    cursor: pointer;

                    &:hover {
                        background: #FAFCFC;
                    }
                }
            }
        }
    }
}