.editor-tabs {
    width: 308px;
    border: 0;
}

.cron-description {
    text-align: center;
    background: var(--primary) !important;
    padding: 8px 0;
    color: white;
}