.tpms-configuration-form {
    margin-top: 20px;

    .form__filed {
        display: flex;
        justify-content: space-between;
        width: 400px;

        .form__input {
            width: 100px;
        }

        .form__feedback {
            margin-left: 10px;
            display: flex;
            align-items: center;
        }
    }

    .btn-secondary {
        border-color: transparent;
    }

    .btn {
        margin-right: 4px;
    }

    .btn-add {
        font-size: 12px;
        width: 50px;
        border: none;
    }

    .edit-button-container {
        margin-top: 20px;
    }

    .asset-container {
        position: relative;

        .axles-container {
            position: absolute;
        }
    }

    .add-remove-buttons {
        margin-left: 30px;
        margin-top: 20px;
    }

    .axle {
        width: auto;
        height: 50px;

        &.singleSteerAxle {
            margin-left: 32px;
            min-width: 168px;
        }

        &.doubleDriveAxle {
            margin-left: 30px;
            min-width: 168px;
        }
        
        &.doubleTrailerAxle {
            margin-left: 28px;
            min-width: 176px;
        }
    }

    .tpms-tractor-rear {
        background: #efefef;
        border-left: 2px solid #e4e4e4;
        border-right: 2px solid #e4e4e4;
        border-bottom: 2px solid #e4e4e4;
        margin-left: 74px;
    }

    .trailer {
        background: #efefef;
        border: 2px solid #e4e4e4;
        margin-left: 22px;
    }

    .position-bubble-tpms {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-left: 15px;
        background-color: #fff;
        border: 5px solid #3ab0c7;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
      
        span {
          font-weight: bold;
        }
    }
      

    b {
        display: inline-block;
        margin-top: 10px;
    }

    .col-wrapper {
        display: flex;
        align-items: flex-start;
    }

    .axle-row {
        align-items: center;
    }
}

.dropdown-menu.show {
    z-index: 100;
}