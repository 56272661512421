@import "../../assets/scss/mixins.scss";


.rivata-menu__list {
    position: absolute !important;
    z-index: 200;

    list-style: none;
    background: #fff;
    border: 1px solid silver;
    border-top: none;
    border-radius: 0 0 5px 5px;
    padding: 0;
    color: #000;

    @include custom-scrollbar(#F5F5F5, silver);

    li {
        padding: 10px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;

        &:hover {
            background: #dcddde;
        }

        b {
            font-size: 1em;
            font-weight: bold;
        }

        label {
            margin: 0;
            font-size: 0.9em;
        }
    }
}