@import "../../assets/scss/mixins.scss";

.info-bubble {
  * {
    overflow-x: hidden;
  }

  .H_ib_close,
  .H_ib_tail {
    display: none;
  }

  .H_ib_body {
    all: revert;
    display: inline-block !important;
  }

  .alert-primary {
    display: flex;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid;
    border-color: rgb(170, 170, 170);
    line-height: 20px;
  }

  .label-line {
    width: 10px;
  }

  .aligned-cell {
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
  }

  td {
    white-space: nowrap;
  }
}

#dashboard-buble {
  font-size: 0.9rem;
  
  .bubble-body {
    background: #fff;
    padding: 0.5rem;
    width: 100%;
    font-size: 0.8rem;

    .row-wrapper {
      display: flex;
      padding: 2px;
      overflow: visible;

      .key {
        width: 90px;
        margin-right: 1rem;
        font-weight: 900;
      }
      
      .value {
        width: 230px;
      }
    }
  }

  .bubble-body-clustered {
    max-height: 160px;
    display: flex;
    background: #fff;
    padding: 0.5rem;
    width: 100%;
    font-size: 0.8rem;
    @include custom-scrollbar(#F5F7F7, #CCCECF);
    .bubble-content {
      display: flex;
      flex-direction: row;
      height: fit-content;
      &__column {
        width: 100px;
        height: auto;
        &:first-of-type {
          margin-left: 0;
        }

        margin: 0 10px;
        overflow-y:hidden;

        .column-container {
          cursor: pointer;
          height: 24px;
          max-height: 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: nowrap;
          overflow: hidden;

          &:hover {
            opacity: 0.75;
          }

          &:active {
            opacity: 0.9;
          }

          &__text {
            font-weight: bold;
            overflow: hidden;
            max-height: 24px;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 80px;
          }

          .tooltip-status-circle {
            margin-top: -2px;
            width: 12px;
            height: 12px;
            border-radius: 100%;
            margin-right: 8px;
          }
        }
      }
    }
  }
}
