.legend-container {
    left: 0px;
    bottom: 0px;
    font-size: 11px;
    z-index: 200;
    max-width: 150px;

    .legend-items-container {
        display: flex;
        flex-direction: column;

        .legend-item {
            display: flex;
            align-items: center;
            height: 20px;
            width: 100%;
            padding: 0 5px 0 5px;

            img {
                width: 10px;
                height: 10px;
                margin-right: 5px;
            }
        }
    }
}