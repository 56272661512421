.table {
  th,
  td {
    vertical-align: middle;

    img {
      max-height: 30px;
      max-width: 30px;
      margin-right: 5px;
    }
  }
}
