.geofence-group {
    &__controls {
        div {
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.group-item {
    &:hover {
        background-color: #F5F7F7;
    }
}