.info-bubble {
  // max-width: 250px;
   .H_ib_close {
     display: none;
   }
 }
 
 #details-buble {
   font-size: 0.9rem;
   
   .bubble-body {
     background: #fff;
     padding: 0.5rem;
     width: 100%;
     font-size: 0.8rem;
 
     .row-wrapper {
       display: flex;
       padding: 2px;
 
       .key {
         width: 150px;
         margin-right: 1rem;
         font-weight:900;
       }
       
       .value {
         width: 170px;
       }
     }
   }
 }