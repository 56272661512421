.assetRow {
  td {
    padding: 0 0.3rem;
  }
}

.sensor-tables {
  & th {
    border-top: none;
  }
}

.nav__item {
  cursor: pointer;

  & a:hover {
    color: #525f7f !important;
  }
}

