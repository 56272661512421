.profile-form {
    .profile-form__wrapper {
        min-height: 300px;
    }

    .profile-form__asset_group, .profile-form__geofence_groups {
        margin-left: 15px;
    }

    .profile-form__checkbox {
        display: flex;
        align-items: center;
        padding: 2px;
        
        label {
            padding-left: 0.5rem;
            margin: 0;
        }

        label, input {
            cursor: pointer;
        }
    }

    .notification-types {
        display: flex;
        align-items: center;

        .notification-types__title {
            width: 100px;
        }

        label {
            margin-right: 1rem;
            margin-bottom: 0;
        }

        input {
            margin-right: 0.2rem;
        }
    }

    .notification-intervals {
        .notification-intervals__title {
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;

            h4 {
                margin-bottom: 0;
            }
        }

        .notification-intervals__info {
            text-align: center;
            width: 25px;
            height: 25px;
            border: 1px solid silver;
            border-radius: 100%;
            cursor: pointer;
            margin-left: 0.5rem;
            font-size: 1rem;
            transition: all 0.4s ease;

            &:hover {
                color: #fff;
                background: #000;
            }
        }

        
    }
}

.notification-intervals__tooltip {
    max-width: 400px;
}