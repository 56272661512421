.charts-control {
    display: flex;
    justify-content: space-between;
    background: #dcddde;
    border-radius: 3px;
    padding: 1rem 0;
    max-width: 1600px !important;
    width: 100%;

    .charts-control__title {
        font-weight: 400;
        margin: 0 0 0 1rem;
        color: #414042;
    }
}