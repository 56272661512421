.no-access-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;

  > div {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .jumbotron {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 60vw;
      height: 40vh;

      .message {
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

.link {
  margin: 1rem;
  color: #007bff;
  cursor: pointer;
}
