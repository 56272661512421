.users-search {
    .user-search__wrapper {
        display: flex;
        position: relative;
        width: 100%;

        .rivata-menu__list {
            top: 34px;
        }
    }
}

.notification-users {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    .manage-notifications__btn {
        max-height: 35px;
    }
}