.time-input-wrapper {
  position: relative;
  display: inline-block;
}

.time-input {
  height: 34px;
  width: 90px;
  padding-left: 8px;
  padding-right: 8px;
}

.time-input.invalid {
  border-color: #f62d51;
  box-shadow: 0 0 0 0.2rem rgba(246, 45, 81, 0.25);
}

.time-input.use-seconds {
  width: 110px;
}

.time-input-icon {
  position: absolute;
  color: black;
  font-size: 16px;
  top: 50%;
  transform: translate(0, -50%);
  right: 8px;
  cursor: pointer;
}

.rivata-menu__list b {
  font-weight: initial;
}