:root {
  --login-bg: url('../../assets/images/riv_brand/image--013.png');
}
.auth-wrapper {
  background-image: var(--login-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.auth-box {
  font-family: myriad-pro;

  .welcome {
    color: #000;
    opacity: 0.9;
    font-weight: 400;
  }

  .btn {
    text-align: left;
    text-transform: uppercase;
    border-radius: 5px;
  }

  .btn-login {
    color: #fff;
    background-color: #33afc9;
  }

  .btn-req {
    border: 1px solid silver;
  }
}

.sidebar-footer {
  & a {
    &:hover {
      color: blue;
    }
  }
}
