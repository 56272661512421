.collapse-arrow-style {
    margin-right: 10px;
    margin-top: 2px;
    transition: 0.2s linear transform;
}

.collapse-arrow-expanded {
    transform: rotateZ(-90deg)
}

.collapsed-header-style {
    height: 20px;
    display: flex;
}

.header-with-subtitle-style {
    height: 36px;
}

.collapsed-header-cursor {
    cursor: pointer;
}

.rivata-module-fullscreen-modal {
    max-width: 100%;
    height: 100%;
    margin: 0!important;
    padding: 20px;

    .modal-content {
        height: 100%;
        #rivata-module-component {
            margin-top: 0px!important;
            padding: 0px!important;
        }
        .card-body, .modal-body{
            padding: 0px!important;
            
        }
        div[data-component='fleet-map'] {
            height: 100%;
            max-width: 100%;
            .card, .collapse, .card-body, .map, .geofence-map, canvas {
                height: 100%!important;
            }  

            // changes for chart components only
            .card-body > div.position-relative:first-of-type, #line_chart {
                height: 98%!important;
                max-height: 98%!important;
    
                canvas.position-absolute {
                    height: unset!important;
                    bottom: unset!important;
                }
            }  
        }

        #add_geofence_button + .geofence-map {
            height: 95%!important;
        }
        
    }
}

.expand-icon {
    width: 26px;
    height: 26px;
    cursor: pointer;
    margin-left: 8px;
    align-self: center;
}