.reportParameterRow {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e9ecef;
    padding: 0 5px;

    .label {
        width: 25%;
        margin: 0;
        display: flex;
        align-items: center;
    }

    .value {
        width: 73%;
        display: flex;
        align-items: center;
        margin: 0;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.075) !important;
    }
}