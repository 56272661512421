.loader {
  margin: 10px 0 10px 0;
}

.document-uploader {
  min-height: 135px;
}

.title-input,
.file-input {
  width: 300px;
}

.edit-title {
  font-weight: bold;
}

.error-message {
  color: red;
}
