@import "../../assets/scss/mixins.scss";

.custom-selector {
    background: #FDFDFD;
    width: 352px;
    border-radius: 5px;
    border: 1px solid #F5F7F7;

    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.05), 0px 16px 24px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.06);

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;

    .custom-selector-body {
        @include custom-menu-scrollbar(#F5F7F7, #CCCECF, #CCCECF);

        overflow-y: auto;
        overflow-x: hidden;
        user-select: none;

        ul, li {
            list-style: none;
        }

        ul {
            margin-bottom: 0;
            padding-left: 0;
            &:first-child {
                    max-height: 500px;
                }
            li {
                padding: 0;

                .menu__item {
                    padding: 12px;
                    border-bottom: 1px solid #F5F7F7;
                    height: 50px;
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    cursor: pointer;
                    
                    .menu__item__wrapper {
                        display: grid;
                        grid-template-columns: 1em auto;
                        gap: 0.5em;
                    }

                    input {
                        margin-right: 12px;
                        cursor: pointer;
                    }

                    input[type="checkbox"], input[type="radio"] {
                        // hide default
                        -webkit-appearance: none;
                        appearance: none;
                        background-color: #fff;
                        margin: 0;

                        display: grid;
                        place-content: center;

                        &.check__marker, &.check__line, &.check__circle {
                            font: inherit;
                            color: currentColor;
                            width: 1.15em;
                            height: 1.15em;
                            border: 1px solid #444545;
                            border-radius: 4px;
                            transform: translateY(-0.075em);
                        }

                        &.check__circle {
                            border-radius: 100%;
                        }

                        &::before {
                            content: "";
                            width: 0.65em;
                            height: 0.65em;
                            transform: scale(0);
                            transition: 120ms transform ease-in-out;
                            box-shadow: inset 1em 1em var(--form-control-color);
                            background-color: #fff;
                        }

                        &.check__marker::before {
                            transform-origin: bottom left;
                            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                        }

                        &.check__line::before {
                            transform-origin: bottom left;
                            clip-path: inset(35% 0% 35% 0% round 5%);
                        }

                        &.check__circle::before {
                            transform-origin: center;
                            clip-path: circle(40%);
                        }

                        &:checked {
                            background-color: var(--primary);
                        }

                        &:checked::before {
                            transform: scale(1);
                        }

                        &:disabled {
                            --form-control-color: var(--form-control-disabled);
                            color: var(--form-control-disabled);
                            cursor: not-allowed;
                        }
                    }

                    .arrow__wrapper {
                        cursor: pointer;
                        width: 2rem;
                        height: 2rem;
                        text-align: center;
                        vertical-align: middle;

                        & .arrow {
                            border: solid #333333;
                            border-width: 0 2px 2px 0;
                            display: inline-block;
                            padding: 5px;
                            margin-top: 50%;
    
                            &.up {
                                transform: rotate(-135deg);
                                -webkit-transform: rotate(-135deg);
                            }
                            
                            &.down {
                                transform: rotate(45deg);
                                -webkit-transform: rotate(45deg);
                            }
                        }
                    }

                    
                }
            }
        }
    }

    .custom-selector-footer {
        display: flex;
        justify-content: flex-end;
        padding: 14px 0px;

        span {
            margin-right: 16px;
            cursor: pointer;

            &.footer__done {
                color: var(--primary);
            }
        }
    }
}

