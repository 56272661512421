.page-titles {
  background: #fff;
  padding: 14px 10px;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 300!important;
  min-height: 61px;

  .divider {
    position: relative;
    height: 24px;
    width: 2rem;
    
    &:before {
      content: "";
      height: 100%;
      width: 2px;
      border-left: solid 2px #e9ecef;
      left: 1rem;
      position: absolute;
    }
  }

  .breadcrumb {
    padding: 0;
    margin: 0;
    background: 0 0;
    font-size: 12px;
  }
}

.top-indent {
  top: 63px !important;
}

@media only screen and (max-width: 1150px) {
  .subnav-children__wrapper {
    button {
      font-size: 12px;
      padding: 0.2rem 0.5rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .subnav-content__wrapper {
    padding-left: 10px!important;
    font-size: 12px;
  }
}