.legal-text {
    color: #2962ff;
    display: flex;
    justify-content: center;

    p {
        width: 100px;
        cursor: pointer;
        padding: 5px;
        margin-block-start: 0;
        margin-block-end: 0;

        &:hover {
            color: #007bff;
        }
    }

}