.custom__input {
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid silver;
    padding: 12px;
    background-color: #FDFDFD;
    position: relative;
    display: flex;
    align-items: center;
    height: 48px;

    input {
        background-color: #FDFDFD;
        border: none;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        width: 100%;
        padding: 0;

        &::placeholder {
            color: #B6B8B8;
        }
    }

    &:focus-within .icon__wrapper {
        display: none;
    }

    svg {
        margin-right: 5px;
    }
}