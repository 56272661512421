.resend-warning-email-result {
    .resend-warning-email-rows-wrapper {
        max-height: 150px;
        overflow-y: auto;
    }

    .resend-warning-email-row {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #e9ecef;
        padding: 0 5px;

        .timestamp {
            width: 140px;
            margin: 0;
            display: flex;
            align-items: center;
        }

        .position,
        .warning-type {
            width: 130px;
            margin: 0;
            display: flex;
            align-items: center;
        }

        .asset-name {
            width: 110px;
            margin: 0;
            display: flex;
            align-items: center;
        }

        .message {
            width: 195px;
            display: flex;
            align-items: center;
            margin: 0;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.075) !important;
        }
    }
}