.request-access {
    width: 100%;
    height: 100vh;
    position: relative;

    .login_link {
        transition: all 0.4s ease;
        border: 1px solid transparent;
        padding: .5rem 1rem;
        border-radius: 5px 0 0 5px;

        &:hover {
            color: #fff;
            border: 1px solid silver;
            background: rgb(51, 175, 201);
        }
    }

    .request-access-footer {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        font-size: 1rem;
        
        & a:hover {
            color: blue;
        }
    }
}