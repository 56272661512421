.info-bubble {
  * {
    overflow-x: hidden;
  }

  .H_ib_close,
  .H_ib_tail {
    display: none;
  }

  .H_ib_content {}

  .H_ib_body {
    all: revert;
    display: inline-block !important;
  }

  .alert-primary-location {
    display: flex;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid;
    font-size: 12px;
  }

  .label-line {
    width: 10px;
  }

  .aligned-cell {
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
  }

  td {
    white-space: nowrap;

    #cargotagDetailsLink {
      text-decoration: underline;
      font-size: 0.8rem;
      cursor: pointer;
    }
  }
}