.positionBubble {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #f9f9f9;
  border: 7px solid #3ab0c7;
  height: 65px;
  width: 65px;
  bottom: -100px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;

  span {
    font-weight: bold;
  }
}

.hubTooltip {
  max-width: 500px;
  text-align: left;
  border-left: 5px solid silver;
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  border-right: 1px solid silver;
  border-radius: 5px;
  background: #fff;
  color: #000;
  border-color: var(--success);
}

.hubTooltip.warning {
  border-color: var(--warning) !important;
}

.hubTooltip.warningSecond {
  margin-left: 500px;
  border-color: var(--warning) !important;
}

.hubTooltip.noStatus {
  border-color: #6D6E71 !important;
}

.hubTooltip.criticalWarning {
  border-color: var(--criticalwarning) !important;
}

.axleLoad {
  margin-top: 3px;
  width: 50px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6D6E71;
  color: #f9f9f9;
  border-radius: 10px;
}

.iconWrapper {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;

  .iconImg {
    border: 4px solid transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    filter: invert(49%) sepia(2%) saturate(606%) hue-rotate(194deg) brightness(87%) contrast(97%);
  }

  .iconBorder,
  .iconImg {
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: 100%;
  }

  .iconPopower {
    width: inherit;
    height: inherit;

    div {
      position: absolute;
      width: inherit;
      height: inherit;
      background-color: transparent;
      z-index: 1234;
    }
  }
}

.dot {
  height: 9px;
  width: 9px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}