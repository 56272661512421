.tire-layer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 76px;
  height: 39px;
  border-width: 4px;
  border-style: solid;
  border-radius: 8px;
  background-color: #666666;
  &.singleSteerAxle,
  &.doubleDriveAxle {
    width: 76px;
    height: 38px;
  }

  span {
    color: #fff;
  }

  &.criticalWarning {
    border-color: var(--criticalwarning);
  }

  &.warning {
    border-color: var(--warning);
  }

  &.normal {
    border-color: var(--success);
  }

  &.noStatus {
    border-color: #6D6E71;
  }

  &.unknown {
    border-color: #666666;
  }
}

.tire-tooltip {
  max-width: 500px;
  text-align: left;
  border-left: 5px solid silver;
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  border-right: 1px solid silver;
  border-radius: 5px;
  background: #fff !important;
  color: #000 !important;

  &.criticalWarning {
    border-color: var(--criticalwarning) !important;
  }

  &.warning {
    border-color: var(--warning) !important;
  }

  &.normal {
    border-color: var(--success) !important;
  }

  &.noStatus {
    border-color: #6D6E71;
  }
}
