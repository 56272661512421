.axle-load-calibration-form {
    .calibration-form__time-range {
        margin-top:28px;
    }

    .units-of-measure__label {
        margin-bottom: 1rem;
        display: flex;
        align-items: flex-end;
        font-size: 1rem;
        width: 1rem;
    }

    .calibration-row {
        min-height: 80px;
    }
}

.calibration-form__date-range {
    position: absolute;
    top: 69px!important;
    z-index: 200;
}