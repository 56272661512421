.axle-load__view-selector {
    display: flex;
    border-radius: 5px;
    background: #fff;
    margin-right: 0.5rem;
    

    span {
        padding: 0.3rem 0.5rem;
        background: #fff;
        cursor: pointer;
        transition: all 0.5s ease;

        &.active {
            background: var(--primary);
            color: #fff;
        }

        &:nth-child(1) {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        &:nth-child(2) {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        
        &:hover {
            background: var(--primary);
            color: #fff;
        }
    }
}