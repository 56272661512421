.whitelabel-add-axle__btn {
    min-width: 100px;
}

.color-picker__wrapper {
    max-height: 350px;
    position: sticky;
    top: 20px;
}

.palette__wrapper {
    max-height: 600px;
    overflow: auto;
}