.reset-config {
    display: flex;
    justify-content: space-between;
    background: #dcddde;
    border-radius: 3px;
    padding: 1rem 0;
    max-width: 1600px!important;
    width: 100%;
    margin: 1rem 15px 0 15px;
    padding-left: 0!important;
    padding-right: 0!important;

    h4 {
        margin-left: 1rem;
        font-weight: 400;
        color: #414042;
    }
    
    button {
        margin-right: 1rem;
    }
}

.reset-styles__warning {
    border-radius: 10px;
}