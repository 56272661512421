.customers_dropdown {
    width: 240px;
    
    .menu {
        width: 240px;
        max-width: 240px;
        margin-top: 0;
    }
    .menu_item {
        font-weight: 600;
    }
    .menu_sub_item {
        padding-left: 24px;
    }
}
