.user-icon {
    position: relative;

    .icon-position {
        position: absolute;
        top: 5px;
        right: -8px;
    }
}

.warning__icon {
    &::before {
        background: #fff;
        color: red;
        border-radius: 100%;
    }
}

.unseen-count-badge {
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--warning);
    border-radius: 100%;
    min-width: 1em;
    height: 1em;
    padding: 0 2px 0 2px;

    div {
        color: black;
        font-size: 0.8em;
    }
}