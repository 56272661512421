.custom-switch .custom-control-input:checked~.custom-control-label::before {
    border-color: var(--primary);
    background-color: var(--primary);
}

.radio__input {
    input[type='radio'] {
        &:checked:after {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            top: -1px;
            left: -1px;
            position: relative;
            background-color: var(--primary);
            content: '';
            display: inline-block;
            visibility: visible;
            border: 2px solid white;
        }

        &:disabled {
            &:checked:after {
                background: #d1d1d1;
            }
        }
    }
}

.units-of-measure__wrapper {
    .units-of-measure__selector {
        display: block;
        padding: 10px 20px;
        border: 1px solid silver;
        background: #fff;
        width: 100px;
        transition: all 0.4s ease;

        &:focus {
            outline: none;
        }

        &:hover {
            background: #f1f1f1;
        }
    }

    .active__unit {
        background: #4472c4;
        color: #fff;

        &:hover {
            background: #4165a7;
        }
    }

    #imperial-units__btn {
        border-right: none;
    }
}

.confirmation-status-list {
    list-style: none;

    li {
        margin-top: 0.5rem;

        i {
            margin-right: 0.5rem;
        }
    }

    .conf-success {
        color: green;
    }

    .conf-error {
        color: red;
    }
}

.errorMessage {
    color: red;
    font-size: 14px;
}

.notification-settings {
    .notification-type {
        display: flex;

        .notification-type__title {
            width: 50px;
        }

        .radio__wrapper input,
        label {
            cursor: pointer;
        }
    }

    .grid-container {
        display: grid;
        width: 30%;
        grid-template-columns: 150px auto auto auto;
        padding: 10px;
    }

    .grid-item {
        border: 1px solid transparent;
        padding: 5px;
        font-size: 0.9rem;
        text-align: center;

        input {
            cursor: pointer;
        }
    }

    .left__aligned {
        text-align: start;
    }
}