.diagram-container {
  width: 100%;
  margin: 50px 0 50px 0;
}

.tractor-rear {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
}

.trailer-rear {
  background: #F3F3F3;
  border: 2.5px solid RGBA(0,0,0, 0.07);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
}

