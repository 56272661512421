@import '../../assets/scss/mixins.scss';

.loader {
  margin: 10px 0 10px 0;
}

.error-message {
  color: red;
}

.clear-button {
  width: 130px !important;
}

.geofence-map {
  height: 400px;
  width: 100%;
  border: 1px solid silver;
}

.geofences-search {
  width: 200px;
}

.asset-vin-input {
  width: 200px;
}

.geofence-info-bubble {
  * {
    overflow-x: hidden;
  }

  .H_ib_close,
  .H_ib_tail {
    display: none;
  }

  .H_ib_body {
    display: inline-block !important;
    background: transparent !important;
    box-shadow: none !important;
    border-radius: 0 !important;

    .H_ib_content {
      margin: 0 !important;
    }
  }

  .alert-primary {
    display: flex;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid;
    line-height: 20px;
  }

  .label-line {
    width: 10px;
  }

  .aligned-cell {
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
  }

  td {
    white-space: nowrap;
  }
}

.copy-form {
  max-height: 300px;
  overflow: auto;
}

.assets-filter {
  animation: assets-filter-in ease-in-out 0.3s;
}

@keyframes assets-filter-in {
  0% {
    max-height: 0;
    opacity: 0;
  }
  50% {
    max-height: 200px;
    opacity: 0;
  }
  100% {
    max-height: unset;
    opacity: 1;
  }
}

.share-form {
  max-height: 250px;
  overflow: auto;

  @include custom-scrollbar(#f5f5f5, silver);
}

.download-sample {
  margin-left: 6px;
  margin-top: 20px;
}

.hidden-in-full-screen {
  visibility: hidden;
  display: none;
}
