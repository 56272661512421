.manage-subscribers__button {
    max-height: 35px;
}

.notification-grid__username {
    display: flex;
    padding: 1rem 0 1rem 0.1rem;

    .btn__remove {
        cursor: pointer;
    }
}