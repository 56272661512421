.table-row:hover {
  background-color: rgba(0, 0, 0, 0.075) !important;
}

.table-row-active {
  background-color: rgb(226, 178, 63);
}

th {
  .form-check-label {
    margin-left: 1.25rem;

    .form-check-input {
      margin-top: 0.17rem;
      margin-left: -1.25rem;
    }
  }
}

.table-row {
  .nav-link {
    padding: 0;
  }

  td {
    a {
      display: block;
      width: 100%;
    }
  }

  &.acknowledged {
    td {
      a {
        color: gray;
       
      }
    }
  }

  .form-check-label {
    display: inline-flex;
    align-items: center;

    img {
      width: 30px;
    }

    .form-check-input {
      margin-top: 0;
      margin-left: 0;
    }
  }
}

.asset-header {
  display: flex;
  justify-content: space-between;
  background: #dcddde;
  border-radius: 3px;
  padding: 1rem 0;
  max-width: 1600px!important;
  width: 100%;
  margin: 0 1rem;

  .asset-header__title {
    font-weight: 400;
    margin: 0 0 0 1rem;
    color: #414042;
  }
}

.alert-icon {
  margin-right: 6px;
}

.warning-type-menu {
  margin-left: -214px;
}

.position-type-menu {
  margin-left: -250px;
}