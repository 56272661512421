.cron_builder {
    max-width: 100% !important;
}

.cron-builder-bg {
    background: var(--primary) !important;
}

.tab-nav {
    border: 1px solid #dcddde;
    .cron_tab {
        background: #fff!important;
    }
    
    .active-cron_tab {
        background: #d7d8d9!important;
    }
}

.edit-form-inputs {
    .error-message {
        font-size: 10px;
    }
}
