.rivata-dropdown__menu {
    overflow: auto;
    overflow-x: hidden;
    max-height: 200px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 290 !important;
}

.singleDropDownItem{
    margin-right: 8px;
    button{
        &:disabled{
            opacity: 1;
            background: #E9ECEF;
            box-shadow: none;
        }

    }

}

.v2-style {
    width: 148px;
    height: 48px;

    >button {
        border: 0.5px solid rgba(204, 206, 207, 1)!important;
        border-radius: 4px;
        background: white !important;
        color: rgba(68, 69, 69, 1) !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: none;

        &:after {
            border: solid black;
            border-width: 0 1px 1px 0;
            display: inline-block;
            width: 8px;
            padding: 4px;
            margin-bottom: 4px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }

        &:hover, &:active {
            color: rgba(68, 69, 69, 1);
        }
    }
}
