.request-access-form {
    padding: 0 10rem;
    
    .request-access-form__title {
        text-align: center;
    }

    .request-access-form__input {
        min-height: 75px;
    }

    input {
        border: 1px solid silver;
    }
}