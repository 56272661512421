// Sidebar
$sidebar-link-padding: 12px 15px;
$sidebar-icon-size: 20px;
$sidebar-first-level-padding: 0 0 10px 0;
$sidebar-first-level-link-padding: 10px 15px;
$sidebar-first-level-icon-size: 14px;
$sidebar-second-level-padding: 0 0 0px 20px;
/*******************
Main sidebar
******************/

.left-sidebar {
  position: absolute;
  width: $sidebar-width-full;
  height: 100%;
  top: 0px;

  z-index: 10;
  padding-top: 64px;
  background: $sidebar;
  box-shadow: $shadow;
  transition: 0.2s ease-in;
}

/*******************
sidebar navigation
******************/
.scroll-sidebar {
  height: calc(100% - 20px);
  overflow: auto;
}

.sidebar-nav {
  ul {
    .sidebar-item {
      width: $sidebar-width-full;
      height: 45px;
      .sidebar-link {
        color: $sidebar-text;
        padding: $sidebar-link-padding;
        display: flex;
        white-space: nowrap;
        align-items: center;
        line-height: 25px;
        opacity: 0.6;
        cursor: pointer;
        i {
          font-style: normal;
          width: 35px;
          line-height: 25px;
          font-size: $sidebar-icon-size;
          color: $sidebar-icons;
          display: inline-block;
          text-align: center;
        }
        &.active,
        &:hover {
          opacity: 1;
        }
      }
      &.selected > .sidebar-link,
      &:hover > .sidebar-link {
        /*background: rgba(0, 0, 0, 0.04);*/
        opacity: 1;
      }
      /*******************
           First level
           ******************/
      .first-level {
        padding: $sidebar-first-level-padding;
        .sidebar-item.active .sidebar-link {
          opacity: 1;
        }
        .sidebar-item .sidebar-link {
          padding: $sidebar-first-level-link-padding;
          i {
            font-size: $sidebar-first-level-icon-size;
            visibility: hidden;
          }
        }
      }
      .second-level .sidebar-item{
        padding: $sidebar-second-level-padding;
      }
    }
    /*******************
         Small cap
         ******************/
    .nav-small-cap {
      font-size: 12px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      line-height: 30px;
      margin-top: 10px;
      color: $sidebar-text;
      opacity: 1;
      i {
        line-height: 30px;
        margin: 0 5px;
      }
    }
  }
}
/*******************
 common m-p removed
******************/
.sidebar-nav {
  ul {
    margin: 0px;
    padding: 0px;
    li {
      list-style: none;
    }
  }
}
.collapse.in {
  display: block;
}
/*******************
Down arrow
******************/
.sidebar-nav .has-arrow {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: 7px;
    height: 7px;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: $sidebar-text;
    margin-left: 10px;
    -webkit-transform: rotate(135deg) translate(0, -50%);
    -ms-transform: rotate(135deg) translate(0, -50%);
    -o-transform: rotate(135deg) translate(0, -50%);
    transform: rotate(135deg) translate(0, -50%);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    top: 26px;
    right: 15px;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
}

/*.sidebar-nav .active > .has-arrow::after,*/

/*.sidebar-nav li.selected > .has-arrow::after,*/
.sidebar-nav li.active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded='true']::after {
  -webkit-transform: rotate(-135deg) translate(0, -50%);
  -ms-transform: rotate(-135deg) translate(0, -50%);
  -o-transform: rotate(-135deg) translate(0, -50%);
  transform: rotate(-135deg) translate(0, -50%);
}

/*******************
use profile section
******************/
.user-profile{
  margin-top: 20px;
  margin-bottom: 11px;

  a {
    display: block;
    width: 50%;
    margin: 0 auto;
  }
  .u-dropdown{
      cursor: pointer;
  }
  .user-pro-body img{
      width: 50px;
      display: block;
      margin: 0 auto 10px;
  }
}
