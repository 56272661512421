.report-parameters-modal {
    max-width: 530px;
    max-height: 410px;
    margin-right: auto !important;
    margin-left: auto !important;

    .report-parameters-body {
        .card-body {
            padding: 10px;
        }
    }
}