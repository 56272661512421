.asset_card_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: 170px;
    padding: 44px 20px 20px 22px;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    border-radius: 16px;
    border: 1px solid rgb(220, 221, 222);
    box-shadow: 2px 4px 0px 0px rgba(220, 221, 222, 0.4);
    margin-top: 10px;

    .asset_card_content {
        width: 47%;
        display: flex;

        .asset_card_content_value {
            font-size: 22px;
        }

        .asset_card_content_label {
            font-size: 12px;
            color: #51544E;

            text-transform: uppercase;
        }

        .asset_card_content_icon_container {
            align-items: center;
            justify-content: center;

            .asset_card_content_icon {
                width: 22px;
                height: 22px;
                object-fit: contain;
                margin-top: 14px;
                margin-right: 10px;
            }
        }
    }

    .asset_card_label {
        top: 12px;
        left: 22px;
        position: absolute;
        font-size: 14px;
        font-weight: bold;
    }
}