.tabs-wrapper {
    width: 100%;

    .card {
        margin-bottom: 0px;
    }

    .nav-link {
        background: #fff!important;
        border: 1px solid #dcddde;
    }

    .nav-tabs {
        border: 0;
    }
}