.country-code__wrapper {
    width: 100%;

    .country-code-dropdown {
    
        background: #fff;
        color: #525f7f;
    
        button {
            min-width: 220px;
            width: 100%;
            background-color: #fff !important;
            color: #525f7f;
    
            &:hover {
                box-shadow: none;
                transform: none;
            }
        }
    
        .country-code-dropdown__label {
            min-width: 220px;
            width: 100%;
            text-align: left;
            border: 1px solid #e9ecef;
    
            &::after {
                position: absolute;
                right: 5%;
                top: 45%;
            }
        }
    }
}

.phone__input {
    margin-left: 14px;
}