.nav-small-cap {
    padding: 0 0 0 15px;
    background: var(--primary);
    width: 100%;

    span,
    i {
        font-family: 'myriad-pro-condensed';
        color: #fff;
        opacity: 0.9;
        font-weight: 700;
        font-size: 16px;
    }

    i {
        opacity: 0;
    }

    height: 30px;
}

.sidebar-link {
    font-size: 16px;
    font-family: 'myriad-pro-condensed';
    font-weight: 700;
    opacity: 0.7;
    height: 45px;
}

.navbarbg {
    i {
        padding: 15px;
    }

    .header-icon {
        color: #fff;
        font-size: 30px;
        cursor: pointer;
    }
}

.user-dropdown__item {
    cursor: pointer;
    padding: 5px 10px;
    width: 100%;
    display: block;
    font-size: 0.8rem;

    &:hover {
        background: #ece7e7;
    }
}