@import "../../../../assets/scss/mixins.scss";


.notification-profile__users {
    max-height: 350px;
    overflow: auto;
    
    @include custom-scrollbar(#F5F5F5, silver);

    .notification__user {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
            background: #d1d1d1;
        }

        & * {
            cursor: pointer;
        }

        label {
            margin: 0;
        }
    }
}