.fleet-summary-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.custom-module-style {
  .card-header {
    height: 72px;
  }
}

.asset_card_item_tooltip {
  .tooltip {
    opacity: 1;

    .tooltip-inner {
      max-width: 300px;
      background-color: #fdfdfd;

      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      border-left-width: 5px;
      border-color: silver;
      padding: 8px 0 8px 0;

      font-weight: 500;
      font-size: 12px;
      color: #1a1a1a;
    }
  }

  &.warning {
    .tooltip {
      .tooltip-inner {
        border-color: var(--warning);
      }
    }
  }

  &.critical_warning {
    .tooltip {
      .tooltip-inner {
        border-color: var(--criticalwarning);
      }
    }
  }
}

.typography_paragraph_2 {
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #1a1a1a;
}

.list-item:not(:last-child) {
  margin-bottom: 0.5rem;
}

.list_item_button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;

  &:focus {
    background-color: rgb(0, 0, 0, 0.12);
  }

  &:hover {
    background-color: rgb(0, 0, 0, 0.04);
  }

  &:active {
    background-color: rgb(0, 0, 0, 0.24);
  }
}
