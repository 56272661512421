.tabs-wrapper {
    width: 100%;

    .card {
        margin-bottom: 10px;
    }

    .assets_tab {
        background: #fff!important;
        border: 1px solid #dcddde;
    }

    .nav-tabs {
        .tab-item {
            color: #3e5569;
            cursor: pointer;
            background: #dcddde;
        }
    
        .active-table_tab {
            background: #d7d8d9!important;
        }
        
        .nav-link:hover, .nav-pills .nav-link:hover {
            color: #3e5569;
        }
    }
}

@media (max-width: 768px) {
    .provision {
        margin-top: 3rem;
    }
}