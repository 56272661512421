#main-wrapper[data-layout="vertical"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin2"],
#main-wrapper[data-layout="horizontal"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin2"] {
  background: #fe5419;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin3"],
#main-wrapper[data-layout="horizontal"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin3"] {
  background: #00b0ff;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin4"],
#main-wrapper[data-layout="horizontal"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin4"] {
  background: #6659f7;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin5"],
#main-wrapper[data-layout="horizontal"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin5"] {
  background: var(--primary) !important;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"],
#main-wrapper[data-layout="horizontal"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"] {
  background: #fff;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"]
  .navbar-brand
  .dark-logo,
#main-wrapper[data-layout="horizontal"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"]
  .navbar-brand
  .dark-logo {
  display: inline;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"]
  .navbar-brand
  .light-logo,
#main-wrapper[data-layout="horizontal"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"]
  .navbar-brand
  .light-logo {
  display: none;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"]
  .nav-toggler,
#main-wrapper[data-layout="vertical"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"]
  .topbartoggler,
#main-wrapper[data-layout="horizontal"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"]
  .nav-toggler,
#main-wrapper[data-layout="horizontal"]
  .topbar
  .top-navbar
  .navbar-header[data-logobg="skin6"]
  .topbartoggler {
  color: #3e5569;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .navbar-collapse[data-navbarbg="skin1"],
#main-wrapper[data-layout="vertical"] .topbar[data-navbarbg="skin1"],
#main-wrapper[data-layout="horizontal"]
  .topbar
  .navbar-collapse[data-navbarbg="skin1"],
#main-wrapper[data-layout="horizontal"] .topbar[data-navbarbg="skin1"] {
  background: #2962ff;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .navbar-collapse[data-navbarbg="skin2"],
#main-wrapper[data-layout="vertical"] .topbar[data-navbarbg="skin2"],
#main-wrapper[data-layout="horizontal"]
  .topbar
  .navbar-collapse[data-navbarbg="skin2"],
#main-wrapper[data-layout="horizontal"] .topbar[data-navbarbg="skin2"] {
  background: #fe5419;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .navbar-collapse[data-navbarbg="skin3"],
#main-wrapper[data-layout="vertical"] .topbar[data-navbarbg="skin3"],
#main-wrapper[data-layout="horizontal"]
  .topbar
  .navbar-collapse[data-navbarbg="skin3"],
#main-wrapper[data-layout="horizontal"] .topbar[data-navbarbg="skin3"] {
  background: #00b0ff;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .navbar-collapse[data-navbarbg="skin4"],
#main-wrapper[data-layout="vertical"] .topbar[data-navbarbg="skin4"],
#main-wrapper[data-layout="horizontal"]
  .topbar
  .navbar-collapse[data-navbarbg="skin4"],
#main-wrapper[data-layout="horizontal"] .topbar[data-navbarbg="skin4"] {
  background: #6659f7;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .navbar-collapse[data-navbarbg="skin5"],
#main-wrapper[data-layout="vertical"] .topbar[data-navbarbg="skin5"],
#main-wrapper[data-layout="horizontal"]
  .topbar
  .navbar-collapse[data-navbarbg="skin5"],
#main-wrapper[data-layout="horizontal"] .topbar[data-navbarbg="skin5"] {
  background: var(--primary) !important;
}
#main-wrapper[data-layout="vertical"]
  .topbar
  .navbar-collapse[data-navbarbg="skin6"],
#main-wrapper[data-layout="vertical"] .topbar[data-navbarbg="skin6"],
#main-wrapper[data-layout="horizontal"]
  .topbar
  .navbar-collapse[data-navbarbg="skin6"],
#main-wrapper[data-layout="horizontal"] .topbar[data-navbarbg="skin6"] {
  background: #fff;
}
#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin1"],
#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin1"] ul,
#main-wrapper[data-layout="horizontal"] .left-sidebar[data-sidebarbg="skin1"],
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin1"]
  ul {
  background: #2962ff;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin1"]
  .create-btn,
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin1"]
  ul
  .create-btn,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin1"]
  .create-btn,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin1"]
  ul
  .create-btn {
  background: #4fc3f7;
}
#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin2"],
#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin2"] ul,
#main-wrapper[data-layout="horizontal"] .left-sidebar[data-sidebarbg="skin2"],
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin2"]
  ul {
  background: #fe5419;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin2"]::after,
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin2"]
  ul::after,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin2"]::after,
#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin3"],
#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin3"] ul,
#main-wrapper[data-layout="horizontal"] .left-sidebar[data-sidebarbg="skin3"],
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin3"]
  ul {
  background: #00b0ff;
}
#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin4"],
#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin4"] ul,
#main-wrapper[data-layout="horizontal"] .left-sidebar[data-sidebarbg="skin4"],
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin4"]
  ul {
  background: #6659f7;
}
#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin5"],
#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin5"] ul,
#main-wrapper[data-layout="horizontal"] .left-sidebar[data-sidebarbg="skin5"],
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin5"]
  ul {
  background: var(--primary) !important;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin5"]
  .create-btn,
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin5"]
  ul
  .create-btn,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin5"]
  .create-btn,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin5"]
  ul
  .create-btn {
  background: #4fc3f7;
}
#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin6"],
#main-wrapper[data-layout="horizontal"] .left-sidebar[data-sidebarbg="skin6"] {
  background: #fff;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul {
  background: #fff;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link {
  color: #212529;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link
  i,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item
  .sidebar-link
  i {
  color: var(--secondary) !important;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .nav-small-cap,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .nav-small-cap {
  color: #212529;
  opacity: 0.7;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  .has-arrow::after,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  .has-arrow::after {
  border-color: #212529;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  .user-content,
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  .user-name,
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  .user-email,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  .user-content,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  .user-name,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  .user-email {
  color: #212529;
}
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .create-btn,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .create-btn {
  background: #4fc3f7;
}

/*******************
 Stickey inner-left-part
*******************/
.left-part {
  border-right: 1px solid #e9ecef;
}

.reverse-mode .left-part {
  border-left: 1px solid #e9ecef;
}

#main-wrapper[data-layout="vertical"][data-boxed-layout="boxed"] {
  box-shadow: 1px 0 80px rgba(0, 0, 0, 0.2);
}

@media (max-width: 767.98px) {
  .left-part {
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  }
}

.topbar {
  background: #fff;
  height: 64px;
}
.topbar .top-navbar .navbar-nav > .nav-item:hover {
  background: rgba(0, 0, 0, 0.03);
}
.topbar .nav-toggler,
.topbar .topbartoggler {
  color: #fff;
}

/*******************
Designed dropdown
******************/
.topbar .dropdown-menu {
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
}
.topbar .dropdown-menu .with-arrow > span {
  background-color: #fff;
}

/*******************
Topbar Responsive
******************/
@media (max-width: 767px) {
  .topbar .top-navbar .navbar-collapse.show,
  .topbar .top-navbar .navbar-collapse.collapsing {
    border-top: 1px solid #e9ecef;
  }
}

/*******************
Main sidebar
******************/
.left-sidebar {
  background: #fff;
}

/*******************
sidebar navigation
******************/
.sidebar-nav ul .sidebar-item .sidebar-link {
  color: #fff;
}
.sidebar-nav ul .sidebar-item .sidebar-link i {
  color: #fff;
}

.sidebar-nav ul .nav-small-cap {
  color: #fff;
}

/*******************
Down arrow
******************/
.sidebar-nav .has-arrow::after {
  border-color: #fff;
}

/*******************
User Profile
******************/
.user-profile .user-content,
.user-profile .user-name,
.user-profile .user-email {
  color: #fff;
}

.create-btn {
  background: #32325d;
}

/*******************
customizer
*******************/
.customizer {
  background: #fff;
}
.customizer .service-panel-toggle {
  background: #dc3545;
  color: #fff;
}

.customizer .customizer-tab .nav-item .nav-link {
  color: #3e5569;
}
.customizer .customizer-tab .nav-item .nav-link:hover,
.customizer .customizer-tab .nav-item .nav-link.active {
  border-bottom: 3px solid #2962ff;
  color: #2962ff;
}

.theme-color .theme-item .theme-link[data-logobg="skin1"],
.theme-color .theme-item .theme-link[data-navbarbg="skin1"],
.theme-color .theme-item .theme-link[data-sidebarbg="skin1"] {
  background: #2962ff;
}
.theme-color .theme-item .theme-link[data-logobg="skin2"],
.theme-color .theme-item .theme-link[data-navbarbg="skin2"],
.theme-color .theme-item .theme-link[data-sidebarbg="skin2"] {
  background: #fe5419;
}
.theme-color .theme-item .theme-link[data-logobg="skin3"],
.theme-color .theme-item .theme-link[data-navbarbg="skin3"],
.theme-color .theme-item .theme-link[data-sidebarbg="skin3"] {
  background: #00b0ff;
}
.theme-color .theme-item .theme-link[data-logobg="skin4"],
.theme-color .theme-item .theme-link[data-navbarbg="skin4"],
.theme-color .theme-item .theme-link[data-sidebarbg="skin4"] {
  background: #6659f7;
}
.theme-color .theme-item .theme-link[data-logobg="skin5"],
.theme-color .theme-item .theme-link[data-navbarbg="skin5"],
.theme-color .theme-item .theme-link[data-sidebarbg="skin5"] {
  background: var(--primary) !important;
}
.theme-color .theme-item .theme-link[data-logobg="skin6"],
.theme-color .theme-item .theme-link[data-navbarbg="skin6"],
.theme-color .theme-item .theme-link[data-sidebarbg="skin6"] {
  background: #e9ecef;
}
