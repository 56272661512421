.axle-load-calibration-table {
    & td:nth-child(-n+2) {
        width: 30px;

    }
}

.calibration-edit-modal__date-range {
    position: absolute;
    z-index: 200;
}

.pick-time-calibration{
    margin-top:19px;
    position:absolute;
    right: 0;
}