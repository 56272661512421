.actions__cell {
    display: flex;
    align-items: center;
    
    .action-icon__wrapper {
        width: 35px;

        i {
            padding: 5px;
            margin: 0 1px;
        }
    }
}