@import "../../assets/scss/mixins.scss";


.customer__selector {
  position: relative;
  input:read-only {
    background-color: #fff!important;  
  }
  input:disabled {
    background-color: #e9ecef !important;
  }
  .customer__input {
    width: 200px;
    cursor: pointer;
  }

  i {
    position: absolute;
    right: 0;
    cursor: pointer;
    padding: 8px;
    transition: all 0.4s ease;
    font-size: 16px;

    &:hover {
      color: red;
    }
  }
}


.custom__menu {
  z-index: 1;
  position: absolute;
  text-align: left;
  top: 40px;
  right: 0;
  max-height: 370px;
  min-width: 200px;
  width: 265px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid silver;
  padding: 5px;
  overflow: hidden;

  .menu__control {
    height: 40px;
    padding: 10px;
    font-weight: 700;
    font-size: 1rem;

    .checkbox__wrapper {
      display: flex;
      align-items: center;
    }

    .menu__input {
      border: 1px solid silver;
      border-radius: 3px;
      max-width: 160px;
      padding: 2px;
      font-size: 14px;
      margin-bottom: 0.5rem;
    }
  }

  .menu__body {
    .parent__elem {
      font-weight: bold;
      padding: 5px 10px;
    }

    .status__message {
      font-size: 1rem;
      font-weight: 600;
    }

    .child_elem {
      // border-left: 2px solid black;
      margin-left: 5px;

      li {
        padding: 10px 15px 0 15px;
        max-width: 165px;
      }
    }

    .menu__list {
      overflow-y: auto;
      max-height: 265px;
      
      @include custom-scrollbar(#F5F5F5, silver);
    }
  }
  
  ul, li {
    list-style: none;
  }

  ul {
    padding: 0;
  }

  li {
    padding: 10px;
    
    & * {
      cursor: pointer;
    }
  }
}