.active-tab__style {
    background: #dcddde !important;
    border-color: #e9ecef #e9ecef #dee2e6 !important;
}

.asset-info__list li span {
    color: #ce8447;
}

.asset-admin-info {
    .date-range-field {
        padding-left: 25px;
        padding-right: 25px;
        margin-right: 5px;
    }

    .date-range-popup_right {
        right: 5px;
        left: auto !important;
    }
}

.json-editor {
    height: 600px;
    --jse-font-size-mono: 11px;
}