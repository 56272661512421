.bulk-upload-validation-result {
    .validation-result-rows-wrapper{
        max-height: 150px;
        overflow-y: auto;
    }
    .validation-result-row {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e9ecef;
    padding: 0 5px;
    .row-number {
        width: 40px;
        margin: 0;
        display: flex;
        align-items: center;
    }
    .vin {
        width: 25%;
        margin: 0;
        display: flex;
        align-items: center;
    }

    .message {
        width: 63%;
        display: flex;
        align-items: center;
        margin: 0;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.075) !important;
    }
}
}