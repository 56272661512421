
    .green {
      filter: brightness(0) saturate(100%) invert(74%) sepia(18%) saturate(1347%) hue-rotate(58deg) brightness(87%) contrast(86%);
    }
  
    .yellow {
      filter: brightness(0) saturate(100%) invert(99%) sepia(72%) saturate(2890%) hue-rotate(332deg) brightness(100%) contrast(99%);
    }
  
    .orange {
      filter: brightness(0) saturate(100%) invert(35%) sepia(36%) saturate(2684%) hue-rotate(3deg) brightness(98%) contrast(103%);
    }
  
    .grey {
      filter: invert(49%) sepia(2%) saturate(606%) hue-rotate(194deg) brightness(87%) contrast(97%);
    }
  
    .wrapper{
      padding-right: 10px;
  
    }
    .icon{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        box-shadow: rgb(109, 110, 113) 0px 0px 2px;
    }

    .lineTooltip {
      max-width: 500px;
      text-align: left;
      border-left: 5px solid silver;
      border-top: 1px solid silver;
      border-bottom: 1px solid silver;
      border-right: 1px solid silver;
      border-radius: 5px;
      background: #fff !important;
      color: #000 !important;
      border-color: #6D6E71 !important;
      &.warning {
        border-color: #ed7624 !important;
      }
      &.normal{
        border-color: #6bbe4c !important;
      }
    }

