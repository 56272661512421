.cargotag-asset__info {
  padding-left: 20px;

  li {
    margin-top: 10px;
    font-weight: 700;

    span {
      font-size: 14px;
      margin-left: 5px;
      color: #ce8447;
    }
  }
}