.module-item {
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}

.module-item-light {
  background-color: #f0f0f0;
}

.module-item-dark {
  background-color: #e5e5e5;
}

.module-item-disabled {
  color: gray;
  background-color: white;
}