.toggle__wrapper {
    .toggle__selector {
        display: block;
        padding: 10px ;
        border: 1px solid silver;
        background: #fff;
        width: 100px;
        transition: all 0.4s ease;

        &:focus {
            outline: none;
        }

        &:hover {
            background: #f1f1f1;
        }
    }

    .active__unit {
        background: #4472c4;
        color: #fff;

        &:hover {
            background: #4165a7;
        }
    }
    
    #imperial-units__btn {
        border-right: none;
    }
}