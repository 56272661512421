.assetRow {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e9ecef;
  padding: 0 5px;

  .label {
    width: 30%;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .value {
    width: 35%;
    display: flex;
    align-items: center;
  }

  .btnWrap {
    width: 10%;
    display: flex;
    justify-content: flex-end;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.075) !important;
  }
}

.favoriteButton {
  opacity: 0.5;
  font-size: 1.3125rem;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
}

.starOn {
  -webkit-text-stroke: 2px black;
  color: gold !important;
}

.starOff {
  -webkit-text-stroke: 2px black;
  color: lightgrey !important;
}