@import "../../../../assets//scss/mixins.scss";

.unverified-modal-list {
    max-height: 200px;
    overflow: auto;

    @include custom-scrollbar(#F5F5F5, silver);

    li {
        padding: 0.2rem;
    }
}