.page-limit__dropdown {
    text-align: left;
    background: #fff;
    color: #525f7f;
    border: 1px solid #dee2e6;
    height: 35px;
    padding-right: 30px;
    margin-left: 0.5rem;
    padding-right: 30px;
  
    button {
        width: 189px;
        background-color: #fff !important;
        color: #525f7f;
  
        &:hover {
            box-shadow: none;
            transform: none;
        }
    }
  
    &::after {
        position: absolute;
        right: 5%;
        top: 45%;
    }
}