/////modal
.noLogsMsg {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 40px;
  color: red;
  font-size: 18px;
  z-index: 0;
}

.header {
  height: 80px;
  justify-content: space-between;

  h5 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.gateLogsOpen.header {
  border: none;
}

.contentWrap {

  .paginationWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;

    .pagination {
      display: flex;
      justify-content: space-between;
      height: 38px;

      div {
        button {
          font-size: 18px;
          height: 38px;
        }
      }
    }

    .gateLogsPagination {
      li {
        display: flex;
        flex-direction: row;
        height: 35px;

        button {
          margin: 0 5px;
        }
      }
    }

    .inputWrap {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 48px;

      .formInput {
        margin-right: 5px;
        height: auto;
        width: 220px;
        cursor: pointer;
      }

      .timePicker {
        display: flex;
        margin-right: 10px;

        .timeLabel {
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0 5px
        }
      }
    }


  }

  .translatorHeader {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
    padding-top: 20px;
    padding: 0 16px 16px 16px;
    border-bottom: 1px solid #e9ecef;
    margin: 0;

    .translatorHeaderItem {
      display: flex;
      justify-content: flex-start;
      font-weight: 600;
      width: 15%;

      &:last-child {
        justify-content: flex-end;
      }

      &:nth-child(3) {
        width: 60%;
      }
    }
  }



  .modalBody {
    min-height: 560px;

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;

      .logItem {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-bottom: 1px solid lightgrey;
        padding: 3px 0;
        margin-bottom: 2px;

        .logInfo {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          .gateLogInfo {
            width: 15%;

            &:nth-child(3) {
              width: 60%;
            }

            &:last-child {
              display: flex;
              justify-content: flex-end;

              button {
                height: 25px;
              }
            }
          }

          .gateLogSource {
            display: flex;
            flex-direction: column;
            width: 60%;
          }

          .dateWrapper {
            width: 25%;
            display: flex;
            flex-direction: column;
          }

          .epoch {
            color: goldenrod;
            justify-content: flex-start;
            padding-left: 0;
          }

          .module {
            padding-top: 5px;
            color: #3299CC;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
          }

          .message {
            width: 70%;
            display: flex;
            justify-content: flex-start;

            .id {
              color: goldenrod;
              margin-right: 10px;
            }
          }
        }

      }
    }
  }
}

.modal {
  max-width: 60vw;
  top: 0;
  min-height: 500px;
}

.behind {
  max-height: 80vh;
}

.calendar {
  position: absolute;
  top: 20px;
  right: -135px;
  transform: translate(-48.1%, 40px);
  border: 1px solid grey;
  border-radius: 4px;
  background-color: white;
  z-index: 999999;

  .calendarHeader {
    background: #DCDDDE;
  }
}

.calendar.gatewayCalendar {
  top: 15px;
  right: 128px;
}

.json {
  padding: 20px 50px;
  background-color: #DCDDDE;
  position: relative;

  .btnJson {
    position: absolute;
    right: 70px;
    top: 30px
  }
}

.calendar.isClose {
  z-index: -2;
}

.json-editor {
  width: 100%;
  height: 100%;
  --jse-font-size-mono: 11px;
}