@mixin custom-scrollbar($color, $tumbColor) {
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: $color;
    }
  
    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
      background-color: $color;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: $tumbColor;
    }
}


@mixin custom-menu-scrollbar($track, $scrollbar, $tumb) {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: $track;
  }

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 0 5px 0 0;
    background-color: $scrollbar;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0 0 0 0;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: $tumb;
  }
}